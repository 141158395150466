<template>

  <div class="row" ref="templateForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">

<!--            //Code-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="code"
                  rules="required"
                  name="The Code"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('Coupon Code')"
                            name="code"
                            fou
                            v-model="formData.code">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
<!--            //Type-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="type"
                  rules="required"
                  name="The Type"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="type"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Type')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Type')"
                    :list="builderData.typeList"
                    :listItemLabel="'name'"
                    :listItemValue="'value'"
                    v-model="formData.type">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

<!--            //Value-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="value"
                  rules="required"
                  name="The Value"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('Value')"
                            name="value"
                            fou
                            v-model="formData.value">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <!--          Members-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="member_id"
                  rules=""
                  name="The Member"
                  v-slot="{ passed, failed,errors }">
                  <!-- <fg-select
                    name="member_id"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Member')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Member')"
                    :list="builderData.memberList"
                    :listItemLabel="'email'"
                    :listItemValue="'id'"
                    v-model="formData.member_id">
                  </fg-select> -->
                  <paginated-select
                    :labelTitle="'Members'"
                    :isMultiple="false"
                    :apiUrl="'/members/paginatedMembers'"
                    :key="paginatedCompKey"
                    :initData="formData.member_id"
                    @select-changed="setMemberId"
                    v-model="formData.member_id"
                  >
                  </paginated-select>
                </ValidationProvider>
              </div>
            </div>

<!--            //IS ACTIVE-->
            <div class="form-group pt-4">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Active') }}</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>
            <l-button @click="$router.push('/referral-coupons/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import LSwitch from "../../../components/Switch";
import PaginatedSelect from "@/components/paginatedSelect";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components:{
    LSwitch,
    FgSelect,
    PaginatedSelect
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      paginatedCompKey: 0,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        code: "",
        type: "",
        value: "",
        member_id: '',
        is_active: true,
      },
      builderData: {
        memberList: [],
        typeList: [],
      }
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.templateForm
    });

    let data = {}
    this.axios.post("referral-coupons/builder", data).then((response) => {
      // this.builderData.memberList = response.data.members;
      this.builderData.typeList = response.data.couponTypes;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Edit Referral Coupon");
        this.getReferralCoupon();
      } else {
        this.editMode = false;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Add Referral Coupon");
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })


  },


  methods: {
    generateSomIdent() {
      if (!this.editMode) {
        let newUrl = '';
        if (this.formData.name) {
          newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
          newUrl = newUrl.replace(/([-]+)/g, "_");
        }
        this.formData.ident = newUrl;
      }
    },
    getReferralCoupon() {
      let data = {
        'id': this.id,
      };
      this.axios.post("referral-coupons/get", data).then((response) => {
        this.formData = response.data;
        this.formData.member_id = this.formData.member_id_cms;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Referral Coupon Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {

      if (this.formData.member_id.id === 'all'){
        this.$notify({
          message: 'You have to choose one member only',
          timeout: 1000,
          type: 'warning'
        });
        return;

      }
      let request;
      let successMessage;
      this.submitting = true;

      this.formData.member_id = this.formData.member_id.id;
      if (this.editMode === true) {
        request = this.axios.put("referral-coupons/update/" + this.id, this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Referral Coupon Updated Successfully");
      } else {
        request = this.axios.post("referral-coupons/create", this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Referral Coupon Added Successfully");
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/referral-coupons/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    setMemberId(memberId) {
      this.formData.member_id = memberId
    },
  }
}
</script>

<style>
</style>
